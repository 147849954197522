import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    { path: '/', name: 'home', component: () => import('@/views/home/HomeView') },

    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/DashboardView'),
        children: [
            { path: 'carousel-customization', name: 'carousel-customization', component: () => import('@/views/dashboard/views/carousel-customization/CarouselCustomization') },
            { path: 'image-customization', name: 'image-customization', component: () => import('@/views/dashboard/views/image-customization/ImageCustomization') },
            { path: 'video-customization', name: 'video-customization', component: () => import('@/views/dashboard/views/video-customization/VideoCustomization') },
            { path: 'rm-customization', name: 'rm-customization', component: () => import('@/views/dashboard/views/rm-customization/RMCustomization') },
            { path: 'products-management', name: 'products-management', component: () => import('@/views/dashboard/views/products-management/ProductsManagementView') },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;