<template>
    <div class="spin" v-if="start" :style="styles">
        <div class="ring">
            <div class="lds-dual-ring">
                <div></div>
                <div></div>
            </div>
        </div>

        <div class="spin-message">
            <h3>{{ message || 'Carregando...' }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpinnerComponent',
    props: [
        'start',
        'message',
        'styles'
    ]
}
</script>

<style scoped>
@import url('@/views/dashboard/components/spinner/styles/main.css');
</style>