<template>
  <SpinnerComponent :key="spin.value" :start="spin.value" :message="spin.message" :styles="spin.styles" />
  <router-view />
</template>

<script setup>
import { ref, provide} from 'vue';
import SpinnerComponent from '@/views/dashboard/components/spinner/SpinnerComponent.vue';

const spin = ref({ value: false, message: 'Carregando...', styles: '' });

provide('makeSpin', spin);
</script>

<style scoped>
@font-face {
  font-family: 'Fivo Sans Modern Bold';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Bold_i6ritq.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern Regular';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Regular_dxkwd3.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern ExtBlack';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-ExtBlack_bo75ny.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern Medium';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Medium_yujzzk.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern Heavy';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Heavy_gchhqo.otf);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  font-family: 'Fivo Sans Modern Regular', sans-serif;  

}
</style>
